<template>
  <div>
    <div class="wrap">
      <h1>买家上门自提功能</h1>
      <p class="yk-tip">启用上门自提功能后，买家可以就近选择商家预设的自提点，下单后商家需要尽快将商品配送至指定自提点。</p>
      <p class="yk-tip">【门店同步】可将企业门店直接转换成自提地址</p>
      <div class="switch">
        <el-switch
          v-model.number="active"
          :active-value="1"
          :inactive-value="0"
          active-text="启用"
          inactive-text="关闭"
          @change="handleChangeSwitch"
        ></el-switch>
      </div>
    </div>
    <div style="margin: 20px 0;">
      <el-button type="primary" size="small" @click="handleAdd">新建自提点</el-button>

      <el-button type="primary" size="small" plain @click="handleAdd"><i class="el-icon-refresh"></i>门店同步</el-button>

      <el-input
        size="small"
        prefix-icon="el-icon-search"
        style="width: 300px;margin-left:24px;margin-right:8px;"
        placeholder="搜索关键字"
        v-model="searchParameter.keyword"
      ></el-input>
      <el-button
        size="small"
        type="primary"
        @click="handleSearch"
      >筛选
      </el-button>
    </div>

    <div>
      <el-table
        class="idefine-table"
        :data="tableData"
      >
        <empty slot="empty"></empty>
        <el-table-column
          property="name"
          label="模板名称"
        ></el-table-column>
        <el-table-column
          property="address"
          label="适用区域"
        ></el-table-column>
        <el-table-column label="最后编辑时间">
          <template slot-scope="scope">{{new Date(scope.row.updatedAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}</template>
        </el-table-column>
        <el-table-column
          label="操作"
          property=""
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleAddCopy(scope.row.uuid)"
            >复制新增
            </el-button>
            &nbsp;
            <el-button
              type="text"
              @click="$router.push({name: 'newTakeTheir',params:{uuid:scope.row.uuid}})"
            >修改
            </el-button>
            &nbsp;
            <el-button
              type="text"
              @click="handleDelete(scope.row.uuid)"
            >删除
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <el-switch
              @change="handleChange(scope.row.status,scope.row.uuid)"
              :active-value="1"
              :inactive-value="0"
              v-model.number="scope.row.status"
            ></el-switch>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        :page-size="searchParameter.limit"
        @current-change="handleCurrentChange"
        @size-change="sizeChange"
        :current-page.sync="currentPage"
        layout=" prev, pager, next,total"
        :total="totalNum"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import {
    CopyDeliverySelfPickPoint, DeleteDeliverySelfPickPoint,
    GetDeliverySetting, UpdateDeliverySetting, DeliverySelfPickPointsList,
    SwtichDeliverySelfPickPoint, DeliverySelfPickPointCount
  } from '@/api/mkt/set'

  export default {
    data() {
      return {
        currentPage: 1,
        totalNum: 0,
        searchParameter: {
          keyword: '',
          status: 0,
          start: 0,
          limit: 10
        },
        active: false,
        tableData: []
      }
    },
    methods: {
      init() {
        GetDeliverySetting().then((res) => {
          this.active = res.data.isSelfPick
        })
        DeliverySelfPickPointsList(this.searchParameter).then((res) => {
          this.tableData = res.data
          DeliverySelfPickPointCount(this.searchParameter).then((res) => {
            this.totalNum = res.data.total
          })
        })
      },
      handleAddCopy(uuid) {
        CopyDeliverySelfPickPoint(uuid).then(res => {
          if (res.code === 0) {
            DeliverySelfPickPointsList(this.searchParameter).then((res) => {
              this.tableData = res.data
              DeliverySelfPickPointCount(this.searchParameter).then((res) => {
                this.totalNum = res.data.total
              })
            })
            this.$message.success('新增成功')
          }
        })
      },
      handleChange(status, uuid) {
        SwtichDeliverySelfPickPoint(uuid, status).then((res) => {
          if (res.code === 0) {
            this.$message.success('修改成功')
          }
        })
      },
      handleDelete(uuid) {
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          DeleteDeliverySelfPickPoint(uuid).then((res) => {
            if (res.code === 0) {
              let Index = this.tableData.indexOf(uuid)
              this.tableData.splice(Index, 1)
              this.totalNum = this.totalNum - 1
              this.$message.success('删除成功')
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      },
      handleAdd() {
        this.$router.push({name: 'newTakeTheir'})
        this.$parent.$parent.menus.style = 'display:none'
      },
      handleSearch() {
        this.searchParameter.start = 0
        this.init()
      },
      handleChangeSwitch(status) {
        let value = status
        let key = 'isSelfPick'
        UpdateDeliverySetting({key, value}).then(() => {
          this.$message({
            type: 'success',
            message: '更改成功!'
          })
        })
      },
      handleCurrentChange(val) {
        this.searchParameter.start = this.searchParameter.limit * (val - 1)
        this.init()
      },
      sizeChange(size) {
        this.searchParameter.limit = size
        this.searchParameter.start = 0
        this.currentPage = 1
        this.init()
      }
    },
    mounted() {
      this.init()
    }
  }
</script>

<style scoped>
</style>
