<template>
  <div class="logistics">
    <el-tabs v-model="active">
      <el-tab-pane :label="ch.name" :name="ch.engName" v-for="ch in chs" :key="ch.engName">
      </el-tab-pane>
    </el-tabs>
    <keep-alive>
      <component :is="active"></component>
    </keep-alive>
  </div>

</template>

<script>
  import sender from './sender'
  import city from './city'
  import owner from './owner'
  export default {
    name: 'setting-logistics',
    components: {
      sender,
      city,
      owner
    },
    mounted: function () {
    },
    data: function () {
      return {
        active: 'sender',
        chs:[
          {
            name: '快递物流',
            engName: 'sender',
          },
          {
            name: '同城派送',
            engName: 'city',
          },
          {
            name: '门店自提',
            engName: 'owner',
          }
        ]
      }
    },
    methods: {},
  }
</script>

<style lang="scss">
  .logistics {
    padding: 0 $container-padding;
    padding-top: $middle-space;

    .wrap {
      background: #f8f8f8;
      padding: 10px;
      position: relative;
      .switch {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
      h1 {
        font-weight: 700;
        margin-bottom: 4px;
        color: $color-black;
      }

      .yk-tip{
        margin-left: 0;
      }
    }

  }
</style>
