<template>
  <div>
    <div class="wrap">
      <h1>同城配送</h1>
      <p class="yk-tip">启用后，买家下单可以选择同城配送，由商家提供上门配送服务</p>
      <div class="switch">
        <el-switch
          v-model.number="active"
          active-text="启用"
          inactive-text="关闭"
          :active-value="1"
          :inactive-value="0"
          @change="handleChangeSwitch"
        ></el-switch>
      </div>
    </div>
    <div>
      <div style="height: 32px;"></div>
      <el-form label-width="80px">
        <el-form-item label="取货地址">
          <el-input
            placeholder="请填写商城门店所在地址"
            v-model="settingObj.pickupAddress"
            type="textarea"
            :autosize="{minRows: 3}"
            style="width: 350px; font-size: 13px"
          ></el-input>
        </el-form-item>
        <el-form-item label="配送方式">
          <el-checkbox
            checked
            disabled> 商家配送</el-checkbox>
          <el-checkbox
            :true-label="1"
            :false-label="0"
            v-model.number="settingObj.isThirdParty"
          >第三方物流配送</el-checkbox>
          <p class="yk-tip">开启后可以选择第三方帮您配送，配送费用从店铺余额中扣除，请保证余额充足。开启即同意</p>
        </el-form-item>
        <el-form-item label="业务类型">
          <div class="wrap">
            <el-radio-group
              @change="handleBizType"
              v-model.number="settingObj.thirdParty.bizType"
            >
              <el-radio
                style="height:35px;"
                :label="1"
              >食品小吃</el-radio>
              <el-radio
                style="height:35px;"
                :label="2"
              >食品饮料</el-radio>
              <el-radio
                style="height:35px;"
                :label="3"
              >鲜花</el-radio>
              <el-radio
                style="height:35px;"
                :label="5"
              >其他</el-radio>
              <el-radio
                style="height:35px;"
                :label="8"
              >文印票务</el-radio>
              <el-radio
                style="height:35px;"
                :label="9"
              >便利店</el-radio>
              <el-radio
                style="height:35px;"
                :label="13"
              >水果生鲜</el-radio>
              <el-radio
                style="height:35px;"
                :label="19"
              >同城电商</el-radio>
              <el-radio
                style="height:35px;"
                :label="20"
              >医药</el-radio>
              <el-radio
                style="height:35px;"
                :label="21"
              >蛋糕</el-radio>
              <el-radio
                style="height:35px;"
                :label="24"
              >酒品</el-radio>
              <el-radio
                style="height:35px;"
                :label="25"
              >小商品市场</el-radio>
              <el-radio
                style="height:35px;"
                :label="26"
              > 服装</el-radio>
              <el-radio
                style="height:35px;"
                :label="27"
              >汽修零配</el-radio>
              <el-radio
                style="height:35px;"
                :label="28"
              >数码</el-radio>
            </el-radio-group>
            <p class="yk-tip">业务类型会影响配送费，请谨慎选择</p>
          </div>
        </el-form-item>
        <el-form-item label="服务商">
          <div style="width: 800px;">
            <el-table
              class="idefine-table"
              :data="settingObj.thirdParty.services"
            >
              <el-table-column label="服务商名称">
                <template slot-scope="scope">
                  {{providerList[scope.row.provider]}}
                </template>
              </el-table-column>
              <el-table-column label="状态">
                <template slot-scope="scope">
                  {{statusList[scope.row.status]}}
                </template>
              </el-table-column>
              <el-table-column
                property="msg"
                label="备注"
              ></el-table-column>
              <el-table-column
                label="操作"
                cell-style
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click="handleOperate(scope.row.provider,scope.row.status)"
                  >{{operateList[scope.row.status]}}</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
      </el-form>
      <div class="wrap">
        <h1>配送区域设置</h1>
      </div>
      <div style="height: 32px;"></div>
      <el-form label-width="110px">
        <el-form-item
          class="is-required"
          label="配送范围介绍"
        >
          <el-input
            v-model="settingObj.areas[0].desc"
            type="textarea"
            maxlength="80"
            show-word-limit
            :autosize="{minRows:3}"
            style="width: 500px;"
          ></el-input>
          <p class="yk-tip">
            配送区域图文信息将会在买家下单时显示。
            <!-- <el-button type="text">查看示例</el-button> -->
          </p>
        </el-form-item>
        <el-form-item
          label="起送金额"
          class="is-required"
        >
          <el-input
            size="small"
            @input="settingObj.areas[0].startFee=settingObj.areas[0].startFee.match(/^\d*(\.?\d{0,2})/g)[0]||null"
            v-model="settingObj.areas[0].startFee"
            prefix-icon="iconfont icon-fl-renminbi"
            style="width: 200px;"
          ></el-input>
          <p class="yk-tip">起送金额为商品促销后的实际售价，在优惠券折扣、订单满减等优惠前，不包含邮费</p>
        </el-form-item>
        <el-form-item
          label="配送费"
          class="is-required"
        >
          <el-input
            @input="settingObj.areas[0].fixedFee=settingObj.areas[0].fixedFee.match(/^\d*(\.?\d{0,2})/g)[0]||null"
            v-model="settingObj.areas[0].fixedFee"
            size="small"
            prefix-icon="iconfont icon-fl-renminbi"
            style="width: 200px;"
          ></el-input>
          <el-checkbox style="margin-left: 16px">夜间加价</el-checkbox>
          <span class="yk-tip">(夜间指晚上22:00以后)</span>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="small"
            @click="handleSubmit"
          >提交审核</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>

  import { GetDeliverySetting, UpdateDeliverySetting, DeliveryCity,
    UpdateCityBizType, UpdateCityThirdParty, UpdateDeliveryCity } from '@/api/mkt/set'
  export default {
    data () {
      const providerList = ['', '达达', '蜂鸟', '点我达', '顺丰', '美团']
      const statusList = ['未开通', '暂不支持', '平台暂不允许入驻', '申请开通中', '审核中', '申请失败', '已开通', '已关闭']
      const operateList = ['申请开通', '', '', '', '', '申请开通', '关闭', '申请开通']
      return {
        providerList,
        statusList,
        operateList,
        obj: {},
        settingObj: {
          isThirdParty: 0,
          areaType: 3,
          pickupAddress: '',
          areas: [
            {
              areaName: '',
              desc: '',
              fixedFee: '',
              image: '',
              startFee: ''
            }
          ],
          thirdParty: {
            bizType: 2,
            services: []
          }

        },
        active: false
      }
    },
    methods: {
      init () {
        GetDeliverySetting().then((res) => {
          this.active = res.data.isCity
        })
        DeliveryCity().then((res) => {
          if (res.data.areas !== null) {
            res.data.areas[0].fixedFee = parseInt(res.data.areas[0].fixedFee) / 100
            res.data.areas[0].startFee = parseInt(res.data.areas[0].startFee) / 100
          } else {
            res.data.areas = [{
              areaName: '',
              desc: '',
              fixedFee: '',
              image: '',
              startFee: ''
            }]
          }
          this.settingObj = res.data
        })
      },
      handleChangeSwitch (status) {
        let value = status
        let key = 'isCity'
        UpdateDeliverySetting({ key, value }).then((res) => {
          if (res.code === 0) {
            this.$message.success('更改成功')
          }
        })
      },
      handleBizType (value) {
        UpdateCityBizType(value).then((res) => {
          if (res.code === 0) {
            this.$message.success('修改成功')
          }
        })
      },
      handleOperate (provider, status) {
        this.$confirm(' 是否继续此操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          switch (status) {
            case 0:
              this.getCityThirdParty(provider, 3)
              break
            case 5:
              this.getCityThirdParty(provider, 3)
              break
            case 6:
              this.getCityThirdParty(provider, 7)
              break
            case 7:
              this.getCityThirdParty(provider, 3)
              break
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      },
      getCityThirdParty (provider, status) {
        UpdateCityThirdParty(provider, status).then((res) => {
          if (res.code === 0) {
            this.init()
            this.$message.success('操作成功')
          }
        })
      },
      handleSubmit () {
        let obj = {
          isThirdParty: this.settingObj.isThirdParty,
          areaType: 3,
          areas: [{
            areaName: this.settingObj.areas[0].areaName,
            desc: this.settingObj.areas[0].desc,
            fixedFee: (this.settingObj.areas[0].fixedFee) * 100,
            image: this.settingObj.areas[0].image,
            startFee: (this.settingObj.areas[0].startFee) * 100
          }]
        }
        UpdateDeliveryCity(obj).then((res) => {
          if (res.code === 0) {
            this.$message.success('提交成功')
          }
        })
      }
    },
    mounted () {
      this.init()
    }
  }
</script>

<style lang="scss" scoped>
</style>
