<template>
  <div contenteditable="true" style="min-height: 280px;"><p>一.商品关联运费模板</p>
  <p><br></p>
  <p>1、商品关联设置：每件商品，仅可设置一种运费收取方式，即：统一运费，或指定运费模板；</p>
  <p>2、统一运费：按商品一次计收运费；如，统一运费10元，买家单笔订单购买n件，均只收取10元运费；</p>
  <p>3、运费模板：选择相应的运费模板，即可指定该商品，可配送至相应区域，并按规则收取相应运费；</p>
  <p><br></p>
  <p>二.运费计算方式</p>
  <p>支持商家选择【按商品累加运费】和【组合运费】，默认使用目前微商城使用的按照商品累加运费，推荐使用组合运费。</p>
  <p>1.按商品累加运费&nbsp; &nbsp;</p>
  <p><br></p>
  <p>【规则】分别计算出来商品使用模板的运费，和统一运费的最大值，再进行累加。</p>
  <p>&nbsp; &nbsp; 1）不同或相同的商品，设置同一运费模板：按该模板设置的规则计算</p>
  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;更新：不足续件或者续重的数目的时候，仍然按照续件或者续重的数目进行计算。</p>
  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;例如商品A，B都是用模板M（首件10块，续2件5块），如果购买商品A和B，各一件，则一共购买两件，运费=10+5=15元。</p>
  <p><br></p>
  <p>&nbsp; &nbsp; 2）多种商品，分别设置不同金额的统一运费：以最高运费金额收取。</p>
  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;更新：例如 商品A，B，C的统一运费分别为1元，2元和3元，一起购买这三个商品，则运费为3元。</p>
  <p><br></p>
  <p>&nbsp; &nbsp; 3）不同的商品，设置不同的运费模板：分别计算每个运费模板规则应收运费，再累加计算合计运费；</p>
  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 例如：
    例如商品A（1kg）使用用模板M（首1kg，10块，续2kg，5块），商品B（0.5kg）使用模板N（首2kg，12块，续1kg，5块），如果购买商品A和B，各2件，则运费=模板M的运费+模板N的运费=（10+5）+（12）=27元。</p>
  <p><br></p>
  <p>&nbsp; &nbsp; 4）统一运费商品，和运费模板商品一同结算：单独计算统一运费商品应收运费，再累加运费模板应收运费；</p>
  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;例如：商品A和B使用统一运费，分别为2元和10元，商品C使用模板M（首1件，10块，续2件，5块），商品D（1kg）使用模板N（首2kg，12块，续1kg，5块），购买A，B，C和D各两件。</p>
  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;此时统一运费（商品A和B）=10元；运费模板运费（商品C和D）=模板M的运费+模板N的运费=（10+5）+（12）=27元。</p>
  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;则总运费=统一运费+运费模板运费=（10）+（27）=37元。</p>
  <p><br></p>
  <p>2.组合运费</p>
  <p>规则：</p>
  <p>先将使用统一运费和运费模板的商品分开计算，再取二者较大的值，作为最终运费。</p>
  <p>1)计算使用运费模板的运费价格x：</p>
  <p>取所有商品中，首件/首重金额最大的运费模板，计算使用该模板的所有商品运费；</p>
  <p>使用其他模板的所有商品都按照该商品所试用的续件/续费金额来计算；</p>
  <p>最后再求和。</p>
  <p><br></p>
  <p>2）计算使用统一运费的商品运费y：取最大的统一运费。</p>
  <p>3）比较x和y，运费=x和y的较大值。</p>
  <p><br></p>
  <p>例如：</p>
  <p><br></p>
  <p>&nbsp; &nbsp; 1）不同或相同的商品，设置同一运费模板：同按商品累加运费的计算方式，按该模板设置的规则计算。</p>
  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;例如商品A，B都是用模板M（首件10块，续2件5块），如果购买商品A和B，各一件，则一共购买两件，运费=10+5=15元。</p>
  <p><br></p>
  <p>&nbsp; &nbsp; 2）多种商品，分别设置不同金额的统一运费：同按商品累加运费的计算方式，以最高运费金额收取。</p>
  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 例如 商品A，B，C的统一运费分别为1元，2元和3元，一期购买这三个商品，则运费为3元。</p>
  <p><br></p>
  <p>&nbsp; &nbsp; 3）不同的商品，设置不同的运费模板：不同于按照商品累加的计算方式。</p>
  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 例如：
    例如商品A（1件）使用用模板M（首1件，10块，续1件，5块），商品B（1kg）使用模板N（首2kg，12块，续1kg，5块），如果购买商品A和B，各2件，则运费=模板N的运费（首费为12，大于模板M的10）+模板M的运费（按照续费计算）=（12）+（5*2）=22元。</p>
  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 如果按商品累加计费，则运费=模板M的运费+模板N的运费=（10+5）+（12）=27元。</p>
  <p><br></p>
  <p>&nbsp; &nbsp; 4）统一运费商品，和运费模板商品一同结算：单独计算统一运费和运费模板，再取较大的数值作为最终运费；</p>
  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;例如：商品A和B使用统一运费，分别为2元和10元，商品C使用模板M（首1件，10块，续2件，5块），商品D（1kg）使用模板N（首2kg，12块，续1kg，5块），购买A，B，C和D各两件。</p>
  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;此时统一运费（商品A和B）=10元；运费模板运费（商品C和D）=模板N的运费（首费为12，大于模板M的10）+模板M的运费（按照续费计算）=（12）+（5*2）=22元。总运费=统一运费和运费模板运费的较大值=22元。</p>
  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;如果按商品累加计费，运费按照之前的计算=统一运费+运费模板运费=（10）+（27）=37元。</p>
  <div><br></div>
  </div>

</template>

<script>
  export default {
    name: '',
    components: {},
    mounted: function () {
    },
    data: function () {
      return {}
    },
    methods: {},
  }
</script>

<style>

</style>
