<template>
  <div>
    <div class="wrap">
      <h1>快递物流</h1>
      <p class="yk-tip">启用后，买家可以选择快递配送，由商家安排快递送货上门服务</p>
      <div style="margin: 10px 0">
        <el-radio-group
          v-model.number="expressCalcMode"
          @change="handleChangeSwitch('expressCalcMode')"
        >
          <el-radio :label="1">
            按商品累加计费
          </el-radio>
          <el-radio :label="2">
            组合运费（推荐）
          </el-radio>
        </el-radio-group>
        <el-button
          type="text"
          style="margin-left: 10px"
          @click="showRule=true"
        >运费计算规则</el-button>
      </div>

      <el-dialog
        title="运费规则说明"
        :visible.sync="showRule">
        <sender-rule></sender-rule>
        <div slot="footer">
          <el-button @click="showRule=false" type="primary" size="small">确定</el-button>
        </div>
      </el-dialog>
      <div class="switch">
        <el-switch
          v-model="active"
          :active-value="1"
          :inactive-value="0"
          active-text="启用"
          inactive-text="关闭"
          @change="handleChangeSwitch('isExpress')"
        ></el-switch>
      </div>

    </div>

    <div style="margin: 10px 0;">
      <el-button type="primary" size="small" @click="$router.push({name: 'newExpressage'})">新建运费模板</el-button>
      <el-input prefix-icon="el-icon-search" style="width: 300px;margin-left:24px;margin-right:8px;" size="small"
                placeholder="搜索关键字" v-model="searchParameter.keyword"></el-input>
      <el-button  type="primary" size="small" @click="handleSearch">筛选</el-button>
    </div>

    <div>
      <el-table
        class="idefine-table"
        :data="tableData"
      >
        <empty slot="empty"></empty>
        <el-table-column
          property="name"
          label="模板名称"
        ></el-table-column>
        <el-table-column label="适用区域">
          <template slot-scope="scope">
            <div
              v-for="(item,index) in scope.row.valuationRules"
              :key="index"
              class="rule-item"
            >
              {{item.region}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="最后编辑时间">
          <template slot-scope="scope">{{new Date(scope.row.updatedAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleAddCopy(scope.row.uuid)"
            >复制新增</el-button>
            &nbsp;
            <el-button
              type="text"
              @click="$router.push({name: 'newExpressage',params:{uuid:scope.row.uuid}})"
            >修改</el-button>
            &nbsp;
            <el-button
              type="text"
              @click="handleDelete(scope.row.uuid)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        :page-size="searchParameter.limit"
        @current-change="handleCurrentChange"
        @size-change="sizeChange"
        :current-page.sync="currentPage"
        layout=" prev, pager, next,total"
        :total="totalNum"
      >
      </el-pagination>
    </div>

  </div>
</template>

<script>
  import { GetDeliverySetting, UpdateDeliverySetting, DeliveryExpresses, DeliveryExpressesCount,
    CopyDeliveryExpress, DeleteDeliveryExpress } from '@/api/mkt/set'

  import SenderRule from './sender-rule'
  export default {
    components:{
      SenderRule
    },
    data () {
      return {
        currentPage: 1,
        showRule: false,
        totalNum: 0,
        searchParameter: {
          keyword: '',
          start: 0,
          limit: 10
        },
        active: false,
        expressCalcMode: 0,
        tableData: [
        ]
      }
    },
    methods: {
      init () {
        GetDeliverySetting().then((res) => {
          this.active = res.data.isExpress
          this.expressCalcMode = res.data.expressCalcMode
        })
        DeliveryExpresses(this.searchParameter).then((res) => {
          this.tableData = res.data
          DeliveryExpressesCount(this.searchParameter).then((res) => {
            this.totalNum = res.data.total
          })
        })
      },
      handleChangeSwitch (defaultKey) {
        let value = defaultKey === 'isExpress' ? this.active : this.expressCalcMode
        let key = defaultKey
        UpdateDeliverySetting({ key, value }).then((res) => {
          if (res.code === 0) {
            this.$message({
              type: 'success',
              message: '更改成功!'
            })
          }
        })
      },
      handleAddCopy (uuid) {
        CopyDeliveryExpress(uuid).then(res => {
          if (res.code === 0) {
            DeliveryExpresses(this.searchParameter).then((res) => {
              this.tableData = res.data
              DeliveryExpressesCount(this.searchParameter).then((res) => {
                this.totalNum = res.data.total
              })
            })
            this.$message.success('新增成功')
          }
        })
      },
      handleDelete (uuid) {
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          DeleteDeliveryExpress(uuid).then((res) => {
            if (res.code === 0) {
              this.init()
              this.$message.success('删除成功')
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      },
      handleSearch () {
        this.searchParameter.start = 0
        this.init()
      },
      handleCurrentChange (val) {
        this.searchParameter.start = this.searchParameter.limit * (val - 1)
        this.init()
        console.log(`当前页: ${val}`)
      },
      sizeChange (size) {
        this.searchParameter.limit = size
        this.searchParameter.start = 0
        this.currentPage = 1
        this.init()
      }
    },
    mounted () {
      this.init()
    }
  }
</script>

<style scoped>
</style>
